/***
**Module Name: contact us 
 **File Name :  contactus.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains contact us table details.
 ***/
 import React, { useState, useEffect, useContext } from "react";
 import Footer from "../../../components/dashboard/footer";
 import Header from "../../../components/dashboard/header";
 import Sidebar from "../../../components/dashboard/sidebar";
 import tmdbApi from "../../../api/tmdbApi";
 import { useHistory, Link } from "react-router-dom";
 import SweetAlert from 'react-bootstrap-sweetalert';
 import axios from 'axios';
 import moment from "moment";
 import ViewContactList from "../contactus/viewContactus";
 import Loader from "../../../components/loader";
 import { contentContext } from "../../../context/contentContext";
 import TableLoader from "../../../components/tableLoader";
 import SessionPopup from "../../SessionPopup"
 import DataTable from 'react-data-table-component';
import ViewAMReport from "./ViewAMReport";

 let { appname, lambda } = window.app;
 
 
 const AMReport = () => {
     const history = useHistory();

    const [ActiveAMType, setActiveAMType]= useState("");
    const [AMGroupData, setAMGroupData]= useState([]);

     const [viewContact, setViewContact] = useState({});
     const [AMSearch, setAMSearch] = useState("");
     const [click, setClick] = useState(false);
     const [showSessionPopupup, setShowSessionPopupup] = useState(false);
     const { isLoading, setIsLoading,userData , data, setData,rowsPerPage, setRowsPerPage,currentPageNew, setCurrentPage,route, setRoute,usePrevious,setActiveMenuObj,setActiveMenuId,  GetTimeActivity} = useContext(contentContext)
 

     const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem=>eachItem.menu == "Reports")
     const subValDashboard = validateObj && validateObj[0] && validateObj[0].submenus && validateObj[0].submenus[2] && validateObj[0].submenus[2].dashboard
 


    const prevRoute = usePrevious(route)
    useEffect(() => {
        if(prevRoute != undefined && prevRoute!=route){
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [prevRoute]);

    useEffect(()=>{
        if(AMSearch==="" && ActiveAMType ===""){
            getAMReport()
        }
       
    },[AMSearch,ActiveAMType])

   const keyForRerender = `${rowsPerPage}_${data.length}`;
    const columns = [

        {
            name: 'Client Name',
            selector: row => row?.name ?? "",
            sortable: true,
        },
        {
            name: 'Company Name',
            selector: row => row?.companyname ?? "",
            sortable: true,
        },
       
        { 
            name: 'Entity Type',
         
            selector: row => row?.entity ?? "",
            // cell: (props) => <div className="text-elipsis">{props.message}</div>,

            sortable: true,
        },
        { 
            name: 'Account Manager',
         
            selector: row => row?.company?.accountmanager ?? "",
            // cell: (props) => <div className="text-elipsis">{props.message}</div>,

            sortable: true,
        },
        { 
            name: 'type',
         
            selector: row => row?.type ?? "",
            // cell: (props) => <div className="text-elipsis">{props.message}</div>,

            sortable: true,
        },
    
     
    //     {
    //         name: <>{subValDashboard && subValDashboard.view && subValDashboard.view.display === true && 'Actions'}</>,
    //         cell: (props) => 
    //        //   {
    //         subValDashboard && subValDashboard.view && subValDashboard.view.display === true &&
    //        <div className="d-flex">
    //        <a className={`${ subValDashboard && subValDashboard.view && subValDashboard.view.enable === false ? 'pe-none':''} text-success action-button`} onClick={(e)=>handleContactView(e,props._id)}><i className="mdi mdi-eye font-size-18"></i></a>

    //    </div>
    //        //  }
    //         ,
    //         ignoreRowClick: true,
    //         allowOverflow: true,
    //         button: true,
    //     },
    ];
   
    const handlePageChange = (page) => {
        GetTimeActivity()   
        setCurrentPage(page);
      };
    
      const handlePerRowsChange = (newPerPage) => {
        GetTimeActivity()   
        setRowsPerPage(newPerPage);
      };
    

   
 
     useEffect(() => {
         if (!localStorage.getItem("token")) {
             history.push("/");
         }
         setRoute("accountmanagementreport")
         setActiveMenuId(300055)
         setActiveMenuObj({
             "Client Management": false,
             "Reports": true
         })
          getAMReport()
            userActivity();
            getAMGroupData();
     }, []);
     const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


       
        const urlLink = lambda + '/useractivity?appname=' + appname + (localStorage.getItem("previousid") ? "&previousid=" + localStorage.getItem("previousid") : "");

        axios({
            method: 'POST',
            url: urlLink,
            // data: payload
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    localStorage.setItem("previousid", response.data.result)
                }
            });
    }
 
  
 
     const customNoRecords = () => {
        return(
            
            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No names were found with the searched keyword</p>
                </div> </div>
        )
    }
 
 
 

 
     const getAMReport = () => {
        setCurrentPage(1);
        GetTimeActivity()    
        // let payload ={};

        const token = localStorage.getItem("token")
       const LinkUrl = `${lambda}/companyReports?appname=${appname}&token=${token}&userid=${localStorage.getItem("userId")}${AMSearch ? `&search=${AMSearch}`:''}${ActiveAMType?`&accountmanagerid=${ActiveAMType}`:''}`
            setIsLoading(true)
          
             axios({
                 method: 'POST',
                 url: LinkUrl,
                //  data:payload
             })
                 .then(function (response) {
                     if (response.data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                         console.log("response", response);
                         setData(response.data.result.data);
                         setIsLoading(false)
                     }
                 });
         
     }
     const handleSearch2 = (item) => {
        GetTimeActivity()   
        setCurrentPage(1);
       console.log('item',item)
       const token = localStorage.getItem("token")
       const LinkUrl = `${lambda}/companyReports?appname=${appname}&token=${token}&userid=${localStorage.getItem("userId")}${AMSearch ? `&search=${AMSearch}`:''}${item?`&accountmanagerid=${item}`:''}`
            setIsLoading(true)
        
             axios({
                 method: 'POST',
                 url: LinkUrl,
                //  data:payload
             })
                 .then(function (response) {
                     if (response.data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                         console.log("response", response);
                         setData(response.data.result.data);
                         setIsLoading(false)
                     }
                 });
         
     }
     const handleChange = (e) => {
       
         setAMSearch(e.target.value)
     }

 
     const handleKeypress = (e) => {
         //it triggers by pressing the enter key
         GetTimeActivity()   
       if ((e.key === "Enter")) {
         setTimeout(function () { 
         getAMReport();
     }, 1000);
       }
     };



      const clearSearch = () => {
        GetTimeActivity()   
        setAMSearch("");
        setActiveAMType("")
     }
     const getAMGroupData = async (item) => {
        GetTimeActivity()   
      
         setIsLoading(true)
         const token = localStorage.getItem("token")
         axios({
           method: 'GET',
           url: lambda + '/accountmangers?appname=' + appname + "&token=" + token + "&userid=" + localStorage.getItem("userId"),
         })
           .then(function (response) {
             console.log("response-->", response?.data)
             if (response?.data?.result == "Invalid token or Expired") {
               setShowSessionPopupup(true)
             } else {
                 console.log("response 3", response?.data?.result);

                 var arrayOfObjects = response?.data?.result;
                 arrayOfObjects.sort((a, b) =>
                     a.username.localeCompare(b.username)
                 );
                 console.log('arrayOfObjects', arrayOfObjects);

                 setAMGroupData(arrayOfObjects)
             }
           });
       }

     const handleAMType = async (e) => {
        GetTimeActivity()   
        const value = e.target.value
        console.log('value',value)
        setCurrentPage(1)
        setRowsPerPage(15)
        setActiveAMType(value)
        handleSearch2(value);
        // getEmailGroupData(value)
      
      
      }

     return (
         <>
 {showSessionPopupup && <SessionPopup />}
             <div id="layout-wrapper">
                 <Header />
                 <Sidebar />
                { click ?  (<ViewAMReport data={viewContact} click={click} setClick={setClick}  />) :
                ( <div className="main-content user-management clients clients-search contact account-manager-report">
 
                     <div className="page-content">
                         <div className="container-fluid">
 
 
 
                             <div className="row mb-4 breadcrumb">
                                 <div className="col-lg-12">
                                     <div className="d-flex align-items-center">
                                         <div className="flex-grow-1">
                                             <h4 className="mb-2 card-title">Account Managers</h4>
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
                             <div className="row table-data">
                                 <div className="col-12">
                                     <div className="card">
                                         <div className="card-body">
                                             <div className="row mb-2">
                                                     <div className="col-sm-4">
                                                         { (userData.type === "ADMIN" ||  userData.type === "MANAGER"||  userData.type === "SUPER ADMIN") &&
                                                         <select name="Type-search" id="dropdown" value={ActiveAMType} className="custom-select custom-select-sm form-control form-control-sm form-select form-select-sm" onChange={e => handleAMType(e)}>
                                                             <option value="">Please Select Account Manager</option>
                                                             {AMGroupData.map(eachItem => <option value={eachItem.userid}>{eachItem.username}</option>)}

                                                         </select>}
                                                     </div>
                                                 <div className="col-sm-8">
 
                                                     <div className="search-box mb-2 d-inline-block">
                                                         <div className="position-relative">
                                                             <input type="text" className="form-control" placeholder="Search name" value={AMSearch} onChange={(e) => handleChange(e)} onKeyPress={handleKeypress} />
                                                             {/* <input className="form-control u-calendar" name="availableFrom" type="date" placeholder="Release Date" max="2023-03-15" value="" />
                                                             <input className="form-control u-calendar" name="availableFrom" type="date" placeholder="Release Date" max="2023-03-15" value="" /> */}
                                                             <button className="fill_btn" onClick={getAMReport}><span className="material-icons search-icon">search</span></button>
                                                      
                                                         </div>
                                                         <div className="dataTables_length" id="datatable_length">
                                                                <button className="fill_btn" onClick={clearSearch}><span className="material-icons-outlined">sync</span>Reset</button>
                                                            </div>
 
                                                      
                                                     </div>
                                                     <div className="text-sm-end">
 
                                                     </div>
                                                 </div>
                                             </div>
 
                                               < DataTable  key={keyForRerender}
                                                 // title=""
                                                 columns={columns}
                                                 // className="table align-middle table-nowrap table-check"
                                                 keyField='_id'
                                                 data={data}
                                                 direction="auto"
                                                 highlightOnHover
                                                 fixedHeaderScrollHeight="300px"
                                                 pagination
                                                 responsive
                                                 persistTableHead
                                                 // selectableRowsVisibleOnly
                                                 striped
                                                 // selectableRowsHighlight
                                                 // selectableRows
                                                 subHeaderAlign="right"
                                                 defaultSortField="name"
                                                 subHeaderWra
                                                 noDataComponent={customNoRecords()}
                                                 paginationTotalRows={data.length}
                                                 onChangeRowsPerPage={handlePerRowsChange}
                                                 onChangePage={handlePageChange}
                                                 paginationPerPage={rowsPerPage}
                                                 paginationDefaultPage={currentPageNew}
                                                paginationRowsPerPageOptions={[15,25,50,75,100]}
                                          
                                                 paginationComponentOptions={{
                                                     rowsPerPageText: 'Per page:',
                                                     rangeSeparatorText: 'of',
                                                     noRowsPerPage: false,
                                                     selectAllRowsItem: false,
                                                     selectAllRowsItemText: 'All',
                                                   }}
                                           
                                                 progressPending={isLoading}
                                                 progressComponent={<TableLoader />}
                                                   />
 
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
 
                         </div>
                     </div>
 
 
 
                     <Footer />
                   
                 </div>)
 }
             </div>
         </>
     );
 };
 
 export default AMReport;
 