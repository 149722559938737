/***
**Module Name: sidebar
 **File Name :  sidebar.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains sidebar details.
 ***/
import React, { useState, useEffect, useContext } from "react";

import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { contentContext } from "../../context/contentContext";


const Sidebar = () => {

    const [manage, setManage] = useState(false);
    const [report, setReport] = useState(false);
    const [settings, setSettings] = useState(false);
    const [auction, setAuction] = useState(false);
    // const [activeMenuObj, setActiveMenuObj] = useState({});
    const history = useHistory();


    const {isLoading, setIsLoading, userData, setUserData, activeMenuObj, setActiveMenuObj ,activeMenuId,GetTimeActivity, setActiveMenuId} = useContext(contentContext)


    const handleMenu = (e, item) => {
         if(!isLoading){
            // console.log('handleMenu-------------->',item.menuid)
            setActiveMenuId(item.menuid)
            let id = item.menu.split(" ").join("").toLowerCase()
            GetTimeActivity()
     
            if (id == "servicesconfig") {
                history.push("/settings/serviceconfig");
            } else if (id == "appconfig") {
                history.push("/settings/" + id);
            }
            else {
                history.push("/" + id);
            }

        }
    }

   
    const handleActiveMenuObj = (e, name) => {
        GetTimeActivity()
        // console.log('handleActiveMenuObj------------>',name)
        setActiveMenuObj({ ...activeMenuObj, [name]: !activeMenuObj[name] })
    }
   
    return (
        <>

            <div className="vertical-menu">

                <div data-simplebar className="h-100">

                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            {/* {userData.type != "SUPER ADMIN" ? <> */}
                                {userData && userData.permissions && userData.permissions.length > 0 ? userData.permissions.map((eachItem) => {
                                    let isDisplay = eachItem.display
                                    let isEnable = eachItem.enable
                                    // console.log('active menu id',eachItem.menuid )
                                    return (

                                        <>
                                            {isDisplay == true &&

                                                
                                                <li key={eachItem.menuid} 
                                                className={`${eachItem.menuid === activeMenuId ? 'active':''} ${isLoading?'pe-none':''}`}
                                                onClick={(e) => eachItem.submenus == undefined ? handleMenu(e, eachItem) : handleActiveMenuObj(e, eachItem.menu)}>
                                                      <a className={`${eachItem.submenus ? 'has-arrow' : ''} waves-effect`}>
                                                        <i className="material-symbols-outlined"> {eachItem.icon} </i>
                                                        <span key="t-dashboards">{eachItem.menu}</span>
                                                    </a>
                                                    {eachItem.submenus && eachItem.submenus.length > 0 &&
                                                        <ul className={activeMenuObj[eachItem.menu] ? "sub-menu mm-show active" : "sub-menu mm-collapse"} aria-expanded="false">
                                                            {eachItem.submenus.map((eachSubmenu) => <li key={eachSubmenu.menuid} className={`${eachSubmenu.menuid === activeMenuId ? 'active':''} ${isLoading?'pe-none':''}`} onClick={(e) => handleMenu(e, eachSubmenu)}><a >{eachSubmenu.menu}</a></li>)}

                                                        </ul>

                                                    }


                                                </li>
                                            }
                                        </>
                                    )
                                }) : null}
                        </ul>
                    </div>
                    <p className="activity_time">Action: {localStorage.getItem("timeActivity")}</p>

                </div>
            </div>

        </>
    );
};

export default Sidebar;
