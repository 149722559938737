/***
**Module Name: signin
 **File Name :  sign.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains signin page details.
 ***/
import React, { useState, useEffect, useContext } from "react";
import tmdbApi from "../api/tmdbApi";
import { useHistory, Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import { contentContext } from "../context/contentContext";
import moment from "moment";
import * as Config from "../constants/Config";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";



const SignIn = () => {
    const [pwd, setPwd] = useState('');
    const [email, setEmail] = useState("");
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [config, setConfig] = useState({});
    const history = useHistory();
    const [image, setImg] = useState('');
    const [showSessionPopup, setShowSessionPopup] = useState(false);
    const [BtnLoader, setBtnLoader] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const { userData, setUserData, currentUrl, setCurrentUrl, activeMenuObj, setActiveMenuObj, GetUserDataContext, setActiveMenuId } = useContext(contentContext)

    // useEffect(() => {
    //     websitedefaults();
    // }, []);
    // const websitedefaults = async () => {
    //     try {
    //         console.log(tmdbApi);
    //         const response = await tmdbApi.websitedefaults();

    //         console.log(response);
    //     } catch {
    //         console.log("error");
    //     }
    // };
    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.resourcesUrl) {
                    setImg(window.site.common.resourcesUrl)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.resourcesUrl) {
            setImg(window.site.common.resourcesUrl)
        }

    }, [window.site]);

    useEffect(() => {
        if ((localStorage.getItem("userId"))) {
            GetUserDataSignIn();
        }
    }, []);

    const handleKeypress = (e) => {
        //it triggers by pressing the enter key

        if ((e.key === "Enter")) {
            setTimeout(function () {
                handleSignIn();
                console.log("called");
            }, 1000);
        }
    };

    const handleSignIn = (e) => {
        console.log("called1");
        if (email === "" && pwd === "") {
            setError("Please enter Email and Password");
            setTimeout(function () { setError("") }, 3000);
        }
        else if (email === "") {
            setError("Please enter Email");
            setTimeout(function () { setError("") }, 3000);
        }
        else if (pwd === "") {
            setError("Please enter Password");
            setTimeout(function () { setError("") }, 3000);
        }
        if (email && pwd) {
            setBtnLoader("loginpwd")
            loginPwd();
        }
    }

    const loginPwd = async () => {
        try {

            const response = await tmdbApi.signin({
                "emailid": email,
                "password": pwd,
                "useragent": JSON.parse(localStorage.getItem("loc"))
            });
            console.log(response);
            if (response.statusCode == 200 && response.result === "Invalid email or password") {
                setError(response.result);
                setTimeout(function () { setError("") }, 3000);
                setBtnLoader("")
            } else if (response.result === "Session Already Exists") {
                // signoutsession()
                setShowSessionPopup(true)
                setBtnLoader("")
            } else if (response.statusCode == 200 && localStorage.getItem("check")) {
                localStorage.setItem("token", response.result.token.token);
                localStorage.setItem("userId", response.result.userid);
                GetUserDataSignIn();
                localStorage.setItem("loginTime", moment().format('MMM-DD-YYYY HH:mm:ss'))
                window.location.replace("/viewcontent/" + localStorage.getItem("check"));
                localStorage.removeItem("check");

            } else {
                localStorage.setItem("token", response.result.token.token);
                localStorage.setItem("userId", response.result.userid);
                GetUserDataSignIn();
                localStorage.setItem("loginTime", moment().format('MMM-DD-YYYY HH:mm:ss'))
                // history.push("/usermanagement");
            }


        } catch {
            console.log("error");
            setBtnLoader("")
            setError("Invalid email or password");
            setTimeout(function () { setError("") }, 3000);
        }
    };
    // console.log('userDatauserDatauserData', userData)
    const GetUserDataSignIn = async () => {
        try {

            const response = await tmdbApi.getUserData({});

            if (response.statusCode === 200) {

                setUserData(response.result[0]);
                localStorage.setItem("ClientName", response.result[0].name)
                localStorage.setItem("ClientType", response.result[0].type)
                let userArr = response.result[0].permissions

                const obj = userArr.reduce((acc, item) => {
                    if (item.submenus) acc[item.menu] = false;
                    return acc;
                }, {});
                // console.log('objjjjjjjj--->',obj)
                console.log("currentUrl",currentUrl);
                if (currentUrl) {
                    console.log("came to currentUrl");
                    history.push(currentUrl)
                } else if (!(localStorage.getItem("check"))) {
                    console.log("else went ");
                    let k = response.result[0]

                    let permissionList = response && response.result && response.result[0] && response.result[0].permissions
                    let filterdList = permissionList.filter((item) => item.display == true)
                    let id = filterdList && filterdList[0] && filterdList[0].menu.split(" ").join("").toLowerCase()

                    history.push("/" + id);



                    setActiveMenuId(filterdList && filterdList[0] && filterdList[0].menuid)
                }

                // setActiveMenuObj(obj)
                setBtnLoader("")
                // console.log('response.result[0] kkkkkkkkkk', response.result[0])
            }
        } catch {
            setBtnLoader("")
            console.log("error");
        }
    };

    const forgotPassword = async () => {
        try {
            setBtnLoader("forgotpwd")
            const response = await tmdbApi.ForgotPassword({
                "emailid": email
            });
            console.log(response);
            if (response.statusCode == 200 && response.result === "Email Sent Successfully") {
                setSuccess(true);
            } else if (response.statusCode == 200 && response.result === "Email does not exists") {
                setError("Email Does Not Exists . Please Enter Valid Email");
                setTimeout(function () { setError("") }, 3000);
            } else {
                setError("Please Enter Valid Email Id");
                setTimeout(function () { setError("") }, 3000);
            }
            setBtnLoader("")
        } catch {
            console.log("error");
            setBtnLoader("")
            setError("Please enter email id");
            setTimeout(function () { setError("") }, 3000);
        }
    };


    const handleForgot = (e) => {
        if (BtnLoader == "") {
            forgotPassword();
        }
    }

    function onConfirm() {
        setSuccess(false);
    };

    const handleShowSessionPopup = () => {
        setShowSessionPopup(true)
    }
    const onCancel = () => {
        setShowSessionPopup(false)
        setBtnLoader("")
    }
    const signoutsession = async () => {
        try {
            setBtnLoader("sessionLoad")
            const response = await tmdbApi.SignOutUser({ emailId: email });
            console.log(response);
            if (response.result == "Success") {
                loginPwd()
            } else {
                setError("Invalid Details");
                setBtnLoader("")
            }
        } catch {
            setBtnLoader("")
            console.log("error");
        }
    }

    const handleMessage = (e) => {
        setError("");
    }
    const togglePassword = (e) => {
        setPasswordShown(!passwordShown);
    };
    return (
        <>
            <Modal className="access-denied" show={showSessionPopup} >

                <div className="modal-body enquiry-form">
                    <div className="container">
                        <button className="close-btn" onClick={e => onCancel()}><span className="material-icons">close</span></button>
                        {/* <span className="material-icons access-denied-icon">https</span> */}
                        <span className="material-icons access-denied-icon">
                            web_asset_off
                        </span>
                        <h3>Multiple Sessions Found</h3>
                        <p>Another session is running in another browser. Do you want to terminate that session?</p>
                        <div className="popup-footer">
                            <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => signoutsession()}>{BtnLoader == "sessionLoad" ? (<img src={image + Config.imgloader + "rotate_right.svg"} className="loading-icon" />) : null}Yes</button>
                            <button className="fill_btn" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => onCancel()}>No</button>
                        </div>
                    </div>
                </div>

            </Modal>

            <div className="home-btn d-none d-sm-block">
                {/* <Link to="/" className="text-dark">
                    <i className="fas fa-home h2" />
                </Link> */}
            </div>
            <div className="account-pages my-5 pt-sm-5 Orasi-signup Orasi-signin">
                <Container>

                    {success && <div className="toast show animate__animated animate__bounce">
                        <div className="toast-header">
                            <p className="me-auto"><span className="toast-icon"><span className="material-icons">check_circle</span>
                            </span>Reset password link sent to your email id</p>
                            <button type="button" className="btn-close" data-bs-dismiss="toast" onClick={e => setSuccess(false)}></button>
                        </div>
                    </div>}


                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            {image && <img src={image + Config.imgmiddle + "logo-light.png?auto=compress,format"} />}
                            <Card className="overflow-hidden">

                                <CardBody className="pt-0">

                                    <div className="p-2">
                                        <div className="form-horizontal">
                                            <h1>Admin Logins</h1>
                                            <p>Enter Valid Credentials to Login</p>

                                            <div className="mb-3 input-field">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Enter Your Email"
                                                    type="email"
                                                    onChange={(e) => setEmail(e.target.value)} value={email} onFocus={(e) => handleMessage(e)}


                                                />

                                            </div>

                                            <div className="mb-3 input-field">
                                                <Label className="form-label">Password</Label>
                                                <input type={passwordShown ? "text" : "password"} className="form-control" id="floatingInput" placeholder="Password" onChange={(e) => setPwd(e.target.value)}
                                                    onKeyPress={handleKeypress} onFocus={(e) => handleMessage(e)} />

                                            </div>
                                            <div className="flex-left terms-block">
                                                <input type="checkbox" id="terms-check" onChange={(e) => togglePassword(e)} />
                                                <label > Show Password.</label>
                                            </div>
                                            {/* <div className="form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customControlInline"
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customControlInline"
                                                >
                                                    Remember Password
                                                </label> */}
                                            {/* </div> */}
                                            {error ? <span className="errormsg" style={{
                                                fontWeight: 'bold',
                                                color: 'red',
                                            }}>{error}</span> : ""
                                            }
                                            <div className="mt-3 d-grid">
                                                <button
                                                    className="btn btn-primary btn-block"
                                                    type="submit" onClick={e => handleSignIn(e)}
                                                >
                                                    {BtnLoader == "loginpwd" ? (<img src={image + Config.imgloader + "rotate_right.svg"} className="loading-icon" />) : null}Login
                                                </button>


                                            </div>



                                            <div className="mt-4 text-center">
                                                <button className="forgot-btn" onClick={e => handleForgot(e)}>
                                                    {BtnLoader == "forgotpwd" ? (<img src={image + Config.imgloader + "rotate_right.svg"} className="loading-icon" />) : null}
                                                    <i className="mdi mdi-lock me-1" />
                                                    Forgot your password?
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">

                                <p>
                                    © {new Date().getFullYear()} SPACOVERS.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default SignIn;
