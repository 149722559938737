/***
**Module Name: contact us 
 **File Name :  contactus.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains contact us table details.
 ***/
 import React, { useState, useEffect, useContext } from "react";
 import Footer from "../../../components/dashboard/footer";
 import Header from "../../../components/dashboard/header";
 import Sidebar from "../../../components/dashboard/sidebar";
 import tmdbApi from "../../../api/tmdbApi";
 import { useHistory, Link } from "react-router-dom";
 import SweetAlert from 'react-bootstrap-sweetalert';
 import axios from 'axios';
 import moment from "moment";
 import ViewContactList from "../contactus/viewContactus";
 import Loader from "../../../components/loader";
 import { contentContext } from "../../../context/contentContext";
 import TableLoader from "../../../components/tableLoader";
 import SessionPopup from "../../SessionPopup"
 import DataTable from 'react-data-table-component';
import ViewVideoRequest from "./viewVideoRequest";
import Tooltip from '@mui/material/Tooltip'

 let { appname, lambda } = window.app;
 
 
 const Videorequest = () => {
     const history = useHistory();
     // const [toggle, setToggle] = useState(false);
     const [content, setContent] = useState("");
     const [perpage, setPerpage] = useState(10);
     const [searchRequesterName, setSearchRequesterName] = useState("");
 
     const [fromdate, setFromDate] = useState("");
     const [todate, setToDate] = useState("");
  
     const [rangeError, setRangeError] = useState(false); 

    //  const [data, setData] = useState([]);
 


     const [sortDirection, setSortDirection] = useState('asc');
    const [arrowdir, setArrowDir] = useState('down');
    const [num, setNum]= useState();
 
     //const [search, setSearch] = useState("");
     const [viewContact, setViewContact] = useState({});
     const [click, setClick] = useState(false);
     const [TitleSearch, setTitleSearch] = useState("");

    //  const [isLoading, setIsLoading] = useState(false);
     const [showSessionPopupup, setShowSessionPopupup] = useState(false);
     const { isLoading, setIsLoading,sortTableAlpha , arrow,sortTableByDate,userData , data, setData,rowsPerPage, setRowsPerPage,currentPageNew, setCurrentPage,route, setRoute,usePrevious,setActiveMenuObj,setActiveMenuId,  GetTimeActivity} = useContext(contentContext)
 

     const validateObj = userData && userData.permissions && userData.permissions.length > 0 && userData.permissions.filter(eachItem=>eachItem.menu == "Reports")
     const subValDashboard = validateObj && validateObj[0] && validateObj[0].submenus && validateObj[0].submenus[2] && validateObj[0].submenus[2].dashboard
    //  console.log('subValDashboard obj',subValDashboard)


 
    const prevRoute = usePrevious(route)
    useEffect(() => {
        if(prevRoute != undefined && prevRoute!=route){
            setCurrentPage(1)
            setRowsPerPage(15)
        }
    }, [prevRoute]);
   //  console.log('prevRoute--->',prevRoute)
   //  console.log('currentRoute--->',route)
   const keyForRerender = `${rowsPerPage}_${data.length}`;
    const columns = [

        {
            name: 'Title',
            selector: row => row?.contentData?.title ?? "",
            sortable: true,
        },
        {
            name: 'Requester name',
            selector: row => row?.requestername ?? "",
            sortable: true,
        },
  
         { 
            name: 'Video Uploaded',
            cell: (props) =>   
            <div className="availability"><span className="material-symbols-outlined">{props?.emailSent == true ?'video_library':'videocam_off'}</span>{props?.emailSent == true ? 'YES':'NO'}</div>
            ,
          
            sortable: false,
        },
        {
           name: 'Requested Date',
        //    selector: row => moment(row.created).utc().format("DD-MMM-YYYY HH:mm"),
           selector: row => row?.created ? new Date(row.created).toLocaleDateString('en-IN', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }): "",
           sortable: true,
       },
       {
        name: 'Video Uploaded Date',
     //    selector: row => moment(row.created).utc().format("DD-MMM-YYYY HH:mm"),
        selector: row =>  row?.videoAvailbleOn ? new Date(row.videoAvailbleOn).toLocaleDateString('en-IN', {
         day: 'numeric',
         month: 'short',
         year: 'numeric',
         hour: 'numeric',
         minute: 'numeric',
       }) : "",
        sortable: true,
    },
        {
            name: <>{subValDashboard && subValDashboard.view && subValDashboard.view.display === true && 'Actions'}</>,
            cell: (props) => 
           //   {
            subValDashboard && subValDashboard.view && subValDashboard.view.display === true &&
           <div className="d-flex">
           <a className={`${ subValDashboard && subValDashboard.view && subValDashboard.view.enable === false ? 'pe-none':''} text-success action-button`} onClick={(e)=>handleContactView(e,props._id)}><i className="mdi mdi-eye font-size-18"></i></a>

       </div>
           //  }
            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handlePageChange = (page) => {
        setCurrentPage(page);
      };
    
      const handlePerRowsChange = (newPerPage) => {
        setRowsPerPage(newPerPage);
      };
    

   
 
     useEffect(() => {
         if (!localStorage.getItem("token")) {
             history.push("/");
         }
         setRoute("videorequests")
         setActiveMenuId(300054)
         setActiveMenuObj({
             "Client Management": false,
             "Reports": true
         })
            getVideoRequest();
            userActivity();
     }, []);
 
     const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": pageName,
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


   const previousId = localStorage.getItem("previousid");
const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    localStorage.setItem("previousid", response.data.result)
                }
            });
    }


     const customNoRecords = () => {
        return(
            
            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No titles were found with the searched keyword</p>
                </div> </div>
        )
    }
    const validate = () => {
        GetTimeActivity()   
        let flag = true
        if (fromdate && todate) {
            if (new Date(fromdate) > new Date(todate)) {
                setRangeError(true)
                flag = false;
            } else {
                setRangeError(false)
            }
        }
        return flag
    }

    const handleRange = (e)=>{
        GetTimeActivity()   
        setRangeError(false)
        if(e.target.name==="requestedfromdate"){
          setFromDate(e.target.value)
        }else{
          setToDate(e.target.value)
        }
    }
     

     const getVideoRequest = () => {
        GetTimeActivity()   
        const valid = validate()
        if(valid){
        setCurrentPage(1);
       
            setIsLoading(true)
            const token = localStorage.getItem("token")
         const linkUrl = `${lambda}/requestMetaDataReports?appname=${appname}&token=${token}&userid=${localStorage.getItem("userId")}` +  (TitleSearch ? `&search=${TitleSearch}` : '') + (searchRequesterName ? `&requestername=${searchRequesterName}` :'');
         let payload ={};

          if(fromdate){
            payload.requestedfromdate =fromdate
          }
          if(todate){
            payload.requestedtodate =todate
          }
        
       
             axios({
                 method: 'POST',
                 url: linkUrl,
                 data:payload
             })
                 .then(function (response) {
                     if (response.data.result == "Invalid token or Expired") {
                        setShowSessionPopupup(true)
                    } else {
                         console.log("response", response);
                         setData(response.data.result.data);
                         setContent(response.data.result);
                         setIsLoading(false)
                     }
                 });
                }
     }
 
     const handleChange = (e) => {
        
         if(e.target.name === "searchTitle")
         {setTitleSearch(e.target.value)}
        else{
            console.log('eeee',e.target.value)
         setSearchRequesterName(e.target.value)
        }
     }
 
     const handleKeypress = (e) => {
         //it triggers by pressing the enter key
         GetTimeActivity()   
       if ((e.key === "Enter")) {
         setTimeout(function () { 
         getVideoRequest();
     }, 1000);
       }
     };

     const handleContactView = (e, id) => {
        GetTimeActivity()   
        for (let key in data) {
          console.log("data",data);
          if (data.hasOwnProperty(key) && data[key]._id === id) {

            setViewContact(data[key]);
            setClick(true);
            
          }
        }
      };

     useEffect(() => {
         if (TitleSearch === "" && todate === "" && fromdate === "" && searchRequesterName === "") {
             getVideoRequest();
         }
     }, [TitleSearch, todate, fromdate, searchRequesterName])
      const clearSearch = () => {
        GetTimeActivity()   
        setTitleSearch("");
        setSearchRequesterName("");
        setToDate("");
        setFromDate("");
        setRangeError(false)
     }
 

    
     return (
         <>
 {showSessionPopupup && <SessionPopup />}
             <div id="layout-wrapper">
                 <Header />
                 <Sidebar />
                { click ?  (<ViewVideoRequest data={viewContact} click={click} setClick={setClick}  />) :
                ( <div className="main-content user-management clients clients-search contact video-request">
 
                     <div className="page-content">
                         <div className="container-fluid">
 
 
 
                             <div className="row mb-4 breadcrumb">
                                 <div className="col-lg-12">
                                     <div className="d-flex align-items-center">
                                         <div className="flex-grow-1">
                                             <h4 className="mb-2 card-title">Video Requests</h4>
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
                             <div className="row table-data">
                                 <div className="col-12">
                                     <div className="card">
                                         <div className="card-body">
                                             <div className="row mb-2">
                                                 <div className="col-sm-4">
 
                                                 </div>
                                                 <div className="col-sm-8">
 
                                                     <div className="search-box mb-2 d-inline-block">
                                                         <div className="position-relative">
                                                             <input type="text" name="searchTitle" className="form-control" placeholder="Search by title" value={TitleSearch} onChange={(e) => handleChange(e)} onKeyPress={handleKeypress} />
                                                             <input type="text" name="searchRName" className="form-control" placeholder="Search by requester name" value={searchRequesterName} onChange={(e) => handleChange(e)}  />

                                                             <div className="input-daterange input-group" id="datepicker6" data-date-format="dd M, yyyy" data-date-autoclose="true" data-provide="datepicker" data-date-container='#datepicker6'>

                                                             <Tooltip title="From Date" placement="top">

                                   <input type="date" className="form-control date-input" name="requestedfromdate" placeholder="Search from" value={fromdate} onChange={(e) => handleRange(e)} max={new Date().toISOString().split('T')[0]} /> 
                                    </Tooltip>

                                   <Tooltip title="To Date" placement="top">

                                   <input type="date" className="form-control date-input" name="requestedtodate" placeholder="Search To" value={todate} onChange={(e) => handleRange(e)} max={new Date().toISOString().split('T')[0]}/>
                                     </Tooltip>
                                 </div>

                                                         
                                                             <button className="fill_btn" onClick={getVideoRequest}><span className="material-icons search-icon">search</span></button>
                                                         </div>
                                                         <div className="dataTables_length" id="datatable_length">
                                                                <button className="fill_btn" onClick={clearSearch}><span className="material-icons-outlined">sync</span>Reset</button>
                                                            </div>
 
                                                      
                                                     </div>
                                                     <div className="text-sm-end">
                                                     {rangeError && <span className="errormsg" style={{ fontWeight: 'bold', color: 'red' }}>To date cannot be earlier than From date</span>}
                                                     </div>
                                                 </div>
                                             </div>
 
                                               < DataTable  key={keyForRerender}
                                                 // title=""
                                                 columns={columns}
                                                 // className="table align-middle table-nowrap table-check"
                                                 keyField='_id'
                                                 data={data}
                                                 direction="auto"
                                                 highlightOnHover
                                                 fixedHeaderScrollHeight="300px"
                                                 pagination
                                                 responsive
                                                 persistTableHead
                                                 // selectableRowsVisibleOnly
                                                 striped
                                                 // selectableRowsHighlight
                                                 // selectableRows
                                                 subHeaderAlign="right"
                                                 defaultSortField="name"
                                                 subHeaderWra
                                                 noDataComponent={customNoRecords()}
                                                 paginationTotalRows={data.length}
                                                 onChangeRowsPerPage={handlePerRowsChange}
                                                 onChangePage={handlePageChange}
                                                 paginationPerPage={rowsPerPage}
                                                 paginationDefaultPage={currentPageNew}
                                                paginationRowsPerPageOptions={[15,25,50,75,100]}
                                          
                                                 paginationComponentOptions={{
                                                     rowsPerPageText: 'Per page:',
                                                     rangeSeparatorText: 'of',
                                                     noRowsPerPage: false,
                                                     selectAllRowsItem: false,
                                                     selectAllRowsItemText: 'All',
                                                   }}
                                           
                                                 progressPending={isLoading}
                                                 progressComponent={<TableLoader />}
                                                   />
 
                                         </div>
                                     </div>
                                 </div>
                             </div>
 
 
                         </div>
                     </div>
 
 
 
                     <Footer />
                   
                 </div>)
 }
             </div>
         </>
     );
 };
 
 export default Videorequest;
 