/***
**Module Name: content dashboard
 **File Name :  contentmanage.js
 **Project :    Orasi Media
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  chandrasekhar
 **author :  Hari
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Dec 29 2022
 **Last modified on: Dec 29 2022
 **Description : contains content mamnagement details.
 ***/
import React, { useState, useEffect, useContext, useRef } from "react";


import Footer from "../../components/dashboard/footer";
import Header from "../../components/dashboard/header";
import Sidebar from "../../components/dashboard/sidebar";
import tmdbApi from "../../api/tmdbApi";
import { useHistory, Link, useLocation } from "react-router-dom";
import axios from 'axios';
import $ from "jquery";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useReactToPrint } from 'react-to-print';
import Button from 'react-bootstrap/Button';
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import TableLoader from "../../components/tableLoader";
import SessionPopup from "../SessionPopup"
import { useParams } from 'react-router-dom';
import Loader from "../../components/loader";
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';

import { contentContext } from "../../context/contentContext";
let { lambda, appname } = window.app





const ViewOrder = () => {
    const history = useHistory();
    let { id } = useParams();

    const [items, setItems] = useState("");
    const [orderInfo, setOrderInfo] = useState("");
    const [image, setImg] = useState('');
    const [itemsView, setItemsView] = useState({});
    const [popup, setPopup] = useState(false);
    const [reason, setReason] = useState("");
    const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
    const [successOrder,setSuccessOrder]=useState(false);
    const [loaderEnable, setLoaderEnable] = useState(false);
    const [isDelete, setIsdelete] = useState(false);
    const[loader,setLoader]=useState(false);
    const { searchedFlag, setSearchedFlag, selctionOrder, setSelectionOrder, sortTableAlpha, arrow, sortTableByDate, contentsearch, setContentSearch, searchPayload, setSearchPayload, contentAdvCount, setContentAdvCount, currentPage, setSelectedOptions, setMultiSelectFields, setActiveFieldsObj, setSelectedOptionsClientName, data, setData, rowsPerPage, setRowsPerPage, currentPageNew, setCurrentPage, route, setRoute, usePrevious, isLoading, setIsLoading, setActiveMenuId, pay, setPay } = useContext(contentContext)





    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/");
        }
        setSelectionOrder(localStorage.getItem("order"))
        getOrder()
        setActiveMenuId(200001)
        setRoute("orders")
        getOrderItems()
        userActivity();
    }, []);
    const userActivity = () => {
        let path = window.location.pathname.split("/");
        const pageName = path[path.length - 1];
        var presentTime = moment();
        let payload;

        payload = {
            "userid": localStorage.getItem("userId"),
            "pagename": "VIEWORDERS",
            "pageurl": window.location.href,
            "starttime": presentTime,
            "useragent": JSON.parse(localStorage.getItem("loc"))

        };


        const previousId = localStorage.getItem("previousid");
        const urlLink = lambda + '/useractivity?appname=' + appname + (previousId ? "&previousid=" + previousId : "");


        axios({
            method: 'POST',
            url: urlLink,
            data: payload
        })
            .then(function (response) {
                if (response.data.statusCode === 200) {
                    localStorage.setItem("previousid", response.data.result)
                }
            });
    }

    const columns = [

        {
            name: 'Image',
            cell: (props) => <img src={
                image + props?.productspecification?.productimage + "?auto=compress,format&width=40"} alt='Image' />,
            sortable: false,
        },
        {
            name: 'item',
            selector: row => row?.productspecification?.productname ?? "",
            sortable: true,
        },
        {
            name: 'Color',
            selector: row => row && row.productspecification && row.productspecification.covercolor,
            sortable: true,
        },
        {
            name: 'Cover Fold',
            selector: row => row && row.productspecification && row.productspecification.coverfold,
            sortable: true,
        },
        {
            name: 'Tie Downs',
            selector: row => row && row.productspecification && row.productspecification.tiedown,
            sortable: true,
        },
        {
            name: 'Tie Downs Loc',
            selector: row => row && row.productspecification && row.productspecification.tiedownlocation,
            sortable: true,
        },
        {
            name: 'Cover Skirt Length',
            selector: row => row && row.productspecification && row.productspecification.coverskritlength,
            sortable: true,
        },
        {
            name: 'Cover Skirt Options',
            selector: row => row && row.productspecification && row.productspecification.skirtoption,
            sortable: true,
        },

        {
            name: 'Actions',
            cell: (props) =>


                <div className="d-flex" >
                    <a
                        onClick={e => handleViewItem(e, props.itemid)}
                        className="text-success action-button"><i className="mdi mdi-eye font-size-18"></i></a>


                </div>

            ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];



    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setRowsPerPage(newPerPage);
    };

    useEffect(() => {

        if (window.site === undefined) {
            setTimeout(() => {
                if (window.site && window.site.common && window.site.common.resourcesUrl) {
                    setImg(window.site.common.resourcesUrl)
                }
            }, 1000);
        }
        if (window.site && window.site.common && window.site.common.resourcesUrl) {
            setImg(window.site.common.resourcesUrl)

        }

    }, [window.site]);





    const customNoRecords = () => {
        return (

            <div className="empty-state-body empty-record"  >
                <div className="empty-state__message">
                    <span className="material-icons">summarize</span>
                    <p className="form-check font-size-16">No content was found for the searched keyword</p>
                </div> </div>
        )
    }
    const handleBack = () => {
        history.push("/orders")
    }
    const handleCloseDealPopup=()=>{
        setIsCancelPopupOpen(false)
        setReason("")
    }
    const handleCancel=()=>{
        setIsCancelPopupOpen(true)
    }
    const handleDelete=()=>{
        setIsdelete(true)
    }
    const handleDeleteOrder = (e,) => {
        setLoader(true)
        axios.delete(lambda + '/deleteorder?appname=' + appname + '&orderid=' + id + '&userid=' + localStorage.getItem("userId") + '&token=' + localStorage.getItem("token"))
            .then(response => {
                if (response.data.result === "deleted successfully") {
                    
                    setIsdelete(false)
                    setLoader(false)
                    history.push("/orders")
                } 

            })
            .catch(error => {
                console.log('Error' + error);
            });
    }
    const getOrder = async () => {
        setIsLoading(true)
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")

        axios({
            method: 'GET',
            url: lambda + '/order?appname=' + appname + "&token=" + token + "&userid=" + userid + "&orderid=" + id,
        })
            .then(function (response) {
                console.log("response", response)
                if (response.data.result == "Invalid token or Expired") {
                    //  setShowSessionPopupup(true)

                } else {
                    setOrderInfo(response.data.result[0])
                    setIsLoading(false)
                }
            });
    };

    const handleCancelOrder = () => {
        setLoaderEnable(true)
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")
const payload={
    "reason":reason
}
        axios({
            method: 'post',
            url: lambda + '/cancelorder?appname=' + appname + "&token=" + token + "&userid=" + userid + "&orderid=" + id,
            data:payload
        })
            .then(function (response) {
                console.log("response", response)
                setReason("");
                   
                if (response?.data?.result === "cancelled successfully") {
                     // Assuming you have a state setter named setIsCancelPopupOpen
                     setIsCancelPopupOpen(false);
                     setLoaderEnable(false)
                     getOrder()
                    setSuccessOrder(true); // Assuming you have a state setter named setSuccessOrder
                }
               
            });

    }
    const getOrderItems = async () => {
        setIsLoading(true)
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")

        axios({
            method: 'POST',
            url: lambda + '/items?appname=' + appname + "&token=" + token + "&userid=" + userid + "&orderid=" + id,
        })
            .then(function (response) {
                console.log("response", response)
                if (response.data.result === "Invalid token or Expired") {
                    //  setShowSessionPopupup(true)

                } else {
                    setItems(response.data.result.data)
                    console.log("result----->", response.data.result.data)
                    //   setOrderInfo(response.data.result.data[0])
                    setIsLoading(false)
                }
            });
    };

    console.log("order", orderInfo)

    const handleViewItem = (e, item) => {
        const token = localStorage.getItem("token")
        const userid = localStorage.getItem("userId")

        axios({
            method: 'get',
            url: lambda + '/item?appname=' + appname + "&token=" + token + "&userid=" + userid + "&itemid=" + item,
        })
            .then(function (response) {
                console.log("response", response)
                if (response.data.result === "Invalid token or Expired") {
                    //  setShowSessionPopupup(true)

                } else {
                    let result = response.data.result[0]
                    // $("#qrImg").attr("src", 'https://api.qrserver.com/v1/create-qr-code/?data=' + "https://development.d2bexra7zdcqo3.amplifyapp.com/item/" + result.itemid + "/" + result.status);
                    setItemsView(response.data.result[0]);
                    setPopup(true)

                }
            });

    }

    const PrintContent = React.forwardRef((props, ref) => {
        useEffect(() => {
            // Your jQuery code here
            $(ref.current).find("#qrImg").attr("src", 'https://api.qrserver.com/v1/create-qr-code/?data=' + "https://admin.spacovers.com/item/" + itemsView.itemid + "/" + itemsView.status);
        }, [ref]);
        return (
            <div ref={ref}  style={{ padding: "30px" }}>
                {/* <div className="modal-body enquiry-form">
                    <img src="https://spacovers.imgix.net/spacoversdev/admin/theme/images/logo-dark.png" />
                    <div className="details_block">
                        <div className="block_left">
                            <div className="input-field">
                                <label className="form-label form-label">delaler</label>
                                <p>{itemsView?.name}</p>
                            </div>
                            <div className="input-field">
                                <label className="form-label form-label">Size</label>
                                <p>{itemsView?.productspecification?.dimensionA + "’" + " " + itemsView?.productspecification?.dimensionB + "”"}</p>
                            </div>

                            <div className="input-field">
                                <label className="form-label form-label">Color</label>
                                <p>{itemsView?.productspecification?.covercolor}</p>
                            </div>
                        </div>
                        <div className="block_right">
                            <div className="input-field">
                                <label className="form-label form-label">Radius</label>
                                <p>{itemsView?.productspecification?.radius}</p>
                            </div>

                        </div>
                    </div>
                    <div className="details_block tie_downs">
                        <div className="block_left">
                            <div className="input-field">
                                <label className="form-label form-label">Plastic<br /># of tie downs</label>
                                <p>{itemsView?.productspecification?.tiedown}</p>
                            </div>
                            <div className="input-field">
                                <label className="form-label form-label">Connector</label>
                                <p>0</p>
                            </div>
                            <div className="input-field">
                                <label className="form-label form-label">Velcro</label>
                                <p>--</p>
                            </div>
                        </div>
                        <div className="block_right">
                            <div className="input-field">
                                <h6>{itemsView?.productspecification?.tiedownlocation}</h6>
                            </div>
                            <div className="input-field">
                                <label className="form-label form-label">Cut Flap</label>
                                <p>{parseInt(itemsView?.productspecification?.coverskritlength) * 2 + 1}</p>
                            </div>
                            <div className="input-field">
                                <label className="form-label form-label">Flaps</label>
                                <p>{itemsView?.productspecification?.coverskritlength}</p>
                            </div>
                        </div>
                    </div>
                    <div className="details_block connected">
                        <div className="block_left">
                            <div className="input-field">
                                <h6>{itemsView?.productspecification?.skirtoption}</h6>
                            </div>
                            <div className="input-field">
                                <label className="form-label form-label">Tie Downs</label>
                                <p>{itemsView?.productspecification?.tiedown}</p>
                            </div>
                        </div>
                        <div className="block_right">
                            <div className="input-field">
                                <h6>{itemsView?.productspecification?.skirtoption}</h6>
                            </div>
                            <div className="input-field">
                                <label className="form-label form-label">Cut T/D</label>
                                <p>{parseInt(itemsView?.productspecification?.tiedown) * 2 + 1}</p>
                            </div>
                        </div>
                    </div>
                    <div className="details_block foam_density">
                        <h6>Foam Density</h6>
                        <div className="dbl">
                            <div className="input-field">
                                <label className="form-label form-label">Dbl</label>
                                {itemsView?.productspecification?.foamdensity ===
                                    "#2lbs Foam +$55" &&
                                    <p>{itemsView?.productspecification?.foamdensity}</p>
                                }
                            </div>
                            <div className="input-field">
                                <label className="form-label form-label">Plastic</label>
                                {itemsView?.productspecification?.foamdensity ===
                                    ".25 Standard" &&
                                    <p>{itemsView?.productspecification?.foamdensity}</p>
                                }
                            </div>
                            <div className="input-field">
                                <label className="form-label form-label">Webbing</label>
                                {itemsView?.productspecification?.foamdensity ===
                                    "5-2.5+$55" &&
                                    <p>{itemsView?.productspecification?.foamdensity}</p>
                                }
                            </div>
                            <div className="input-field">
                                <label className="form-label form-label">6”- 4”</label>
                                {itemsView?.productspecification?.foamdensity ===
                                    "6-4 +$100" &&
                                    <p>{itemsView?.productspecification?.foamdensity}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="qr_block">
                        <img src="https://spacovers.imgix.net/spacoversdev/admin/theme/images/qr-code.png" className="qrImg" id="qrImg" />
                    </div>
                    <div className="details_block customer_location">
                        <div className="input-field">
                            <label className="form-label form-label">Customer / Location</label>
                            <p>{itemsView && itemsView?.deliveryaddress && (itemsView?.deliveryaddress?.address1 + "," + itemsView.deliveryaddress.address2 + "," + itemsView.deliveryaddress.state + "," + itemsView.deliveryaddress.pincode)}</p>
                        </div>
                        <div className="input-field">
                            <label className="form-label form-label">Date</label>
                            <p>{moment(itemsView?.created).format('MMM-DD-YYYY')}</p>
                        </div>
                    </div>
                </div> */}
<div  style={{ padding: "30px" }}>
                <table style={{fontFamily: "Arial, Helvetica, sans-serif", width: "100%",borderBottom: "1px solid rgb(239, 244, 247)",margin: 0,alignItems: "center"}}>
                    <tbody>
                        <tr>
                            <td style={{ width: "43.33%", verticalAlign: "top", padding: "5px 0px" }}>
                                <img
                                    style={{ width: 68 }}
                                    src="https://spacovers.imgix.net/spacoversdev/admin/theme/images/logo-dark.png"
                                />
                            </td>
                            <td style={{width: "33.33%", textAlign: "left",
                                    verticalAlign: "bottom"
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: 11,
                                        fontWeight: 500,
                                        color: "rgba(91, 91, 91, 1)",
                                        margin: 0
                                    }}
                                >
                                    PO #
                                </p>
                                <p
                                    style={{
                                        fontSize: 11,
                                        fontWeight: 700,
                                        color: "rgba(80, 80, 80, 1)",
                                        margin: 0
                                    }}
                                >
                                   {itemsView?.ponumber}
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "23.33%",
                                    textAlign: "right",
                                    verticalAlign: "bottom",
                                    fontSize: 11,
                                    fontWeight: 700,
                                    color: "rgba(80, 80, 80, 1)",
                                    margin: 0
                                }}
                            >
                                {moment(itemsView?.created).format('MMM-DD-YYYY')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    style={{
                        color: "rgb(0, 0, 0)",
                        fontSize: 11,
                        fontFamily: "Arial, Helvetica, sans-serif",
                        width: "100%"
                    }}
                >
                    <table
                        style={{
                            width: "100%",
                            fontSize: 11,
                            fontFamily: "Arial, Helvetica, sans-serif"
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 11,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "60%",
                                        borderBottom: "1px solid rgb(239, 244, 247)"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 11,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "24%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Dealer
                                                </td>
                                                <td
                                                    style={{
                                                        width: "76%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                   {itemsView?.name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "24%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Size
                                                </td>
                                                <td
                                                    style={{
                                                        width: "76%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700
                                                    }}
                                                >
                                                   {itemsView?.productspecification?.dimensionA + "’" + " " + itemsView?.productspecification?.dimensionB + "”"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 11,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "40%",
                                        borderBottom: "1px solid rgb(239, 244, 247)"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 11,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "40%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Radius
                                                </td>
                                                <td
                                                    style={{
                                                        width: "60%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    {itemsView?.productspecification?.radius}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "40%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Color
                                                </td>
                                                <td
                                                    style={{
                                                        width: "60%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700
                                                    }}
                                                >
                                                   {itemsView?.productspecification?.covercolor}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{
                            width: "100%",
                            fontSize: 11,
                            fontFamily: "Arial, Helvetica, sans-serif"
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 11,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "67%",
                                        borderBottom: "1px solid rgb(239, 244, 247)"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 11,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "10%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 5
                                                    }}
                                                >
                                                    {itemsView?.productspecification?.tiedown}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "90%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    <p style={{ margin: 0 }}>Plastic</p>
                                                    <p style={{ margin: 0 }}># Of Tie Downs</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "10%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    0
                                                </td>
                                                <td
                                                    style={{
                                                        width: "90%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Connector
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "10%",
                                                        verticalAlign: "top",
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    {/* <img
                                                        style={{ width: "11.5px" }}
                                                        src="https://d1yfmfkyn2ebhw.cloudfront.net/images/app/images/check-circle.png"
                                                    /> */}
                                                    --
                                                </td>
                                                <td
                                                    style={{
                                                        width: "90%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Velcro
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "10%",
                                                        verticalAlign: "top",
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: "11.5px" }}
                                                        src="https://d1yfmfkyn2ebhw.cloudfront.net/images/app/images/check-circle.png"
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        width: "90%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Custom
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 11,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "33%",
                                        borderBottom: "1px solid rgb(239, 244, 247)"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 11,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "18%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    {parseInt(itemsView?.productspecification?.coverskritlength) * 2 + 1}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "82%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Cut Flap
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "18%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    {itemsView?.productspecification?.coverskritlength}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "82%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Flaps
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "18%",
                                                        verticalAlign: "top",
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: "11.5px" }}
                                                        src="https://d1yfmfkyn2ebhw.cloudfront.net/images/app/images/check-circle.png"
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        width: "82%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 5
                                                    }}
                                                >
                                                    Connected
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "18%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
{itemsView?.productspecification?.tiedown}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "82%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Tie Downs
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "18%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700
                                                    }}
                                                >
                                                    {parseInt(itemsView?.productspecification?.tiedown) * 2 + 1}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "82%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Cut T/D
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{
                            width: "100%",
                            fontSize: "10.5px",
                            fontFamily: "Arial, Helvetica, sans-serif"
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: "10.5px",
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "22%",
                                        borderBottom: "1px solid rgb(239, 244, 247)"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: "10.5px",
                                            fontFamily: "Arial, Helvetica, sans-serif"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "7%", verticalAlign: "top" }}>
                                                    <img
                                                        style={{ width: 11 }}
                                                        src="https://d1yfmfkyn2ebhw.cloudfront.net/images/app/images/check-circle.png"
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        width: "93%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)"
                                                    }}
                                                >
                                                        {itemsView && itemsView?.productspecification && itemsView?.productspecification?.upgrades && itemsView?.productspecification?.upgrades?.map((eachItem, key) => {
                                        return (
                                    <td key={key}><p>{eachItem}</p></td>
                                 
                                        )

                                    })}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{
                            width: "100%",
                            fontSize: 10,
                            fontFamily: "Arial, Helvetica, sans-serif"
                        }}
                    >
                        <tbody>
                            <tr>
                                <td style={{ verticalAlign: "top", width: "40%", paddingTop: 7 }}>
                                    <img
                                        style={{
                                            width: 115,
                                            height: 115,
                                            padding: 8,
                                            border: "1px solid rgba(215, 215, 215, 1)"
                                        }}
                                         src="https://spacovers.imgix.net/spacoversdev/admin/theme/images/qr-code.png" className="qrImg" id="qrImg"
                                    />
                                </td>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 10,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "60%",
                                        paddingLeft: 5,
                                        paddingTop: 7
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 10,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "100%",
                                                        verticalAlign: "top",
                                                        paddingBottom: 4
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 60 }}
                                                        src={`${image}${itemsView && itemsView?.productspecification?.productimage}`}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: "9.5px",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "44%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontSize: 10,
                                                        fontWeight: 500,
                                                        margin: 0,
                                                        paddingBottom: 0
                                                    }}
                                                >
                                                    Foam Density
                                                </td>
                                                <td
                                                    style={{
                                                        width: "56%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        fontSize: 10,
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        margin: 0
                                                    }}
                                                >
                                                    {itemsView?.productspecification?.foamdensity}
                                                </td>
                                            </tr>   
                                        </tbody>
                                    </table>
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: "9.5px",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Cust/Add
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700
                                                    }}
                                                >
                                                   {itemsView && itemsView?.deliveryaddress && (itemsView?.deliveryaddress?.address1 + "," + itemsView.deliveryaddress.address2 + "," + itemsView.deliveryaddress.state + "," + itemsView.deliveryaddress.pincode)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{
                            width: "100%",
                            fontSize: 10,
                            fontFamily: "Arial, Helvetica, sans-serif",
                            paddingTop: 5
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 10,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "100%"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 10,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "100%", verticalAlign: "top" }}>
                                                    <p
                                                        style={{
                                                            color: "rgba(91, 91, 91, 1)",
                                                            fontSize: 10,
                                                            fontWeight: 700,
                                                            margin: 0,
                                                            paddingBottom: 2
                                                        }}
                                                    >
                                                        Note
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: 10,
                                                            color: "rgba(80, 80, 80, 1)",
                                                            fontWeight: 500,
                                                            margin: 0,
                                                            wordBreak: "break-word"
                                                        }}
                                                    >
                                                       {itemsView?.productspecification?.additionalinstructions}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>

            </div>
        )
    });

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const componentRef1 = useRef();
    const handlePrint1 = useReactToPrint({
        content: () => componentRef1.current,
    });
    const PageContent = ({ data }) => {
        const [qrSrc, setQrSrc] = useState('');
        useEffect(() => {
            const imageUrl = 'https://api.qrserver.com/v1/create-qr-code/?data=' +
              `https://admin.spacovers.com/item/${data.itemid}/${data.status}`;
            setQrSrc(imageUrl);
        }, [data.itemid, data.status]);



        return (
            <div style={{ pageBreakAfter: 'always' }}>

                <div  style={{ padding: "30px" }}>
                <table style={{fontFamily: "Arial, Helvetica, sans-serif", width: "100%",borderBottom: "1px solid rgb(239, 244, 247)",margin: 0,alignItems: "center"}}>
                    <tbody>
                        <tr>
                            <td style={{ width: "43.33%", verticalAlign: "top", padding: "5px 0px" }}>
                                <img
                                    style={{ width: 68 }}
                                    src="https://spacovers.imgix.net/spacoversdev/admin/theme/images/logo-dark.png"
                                />
                            </td>
                            <td style={{width: "33.33%", textAlign: "left",
                                    verticalAlign: "bottom"
                                }}
                            >
                                <p
                                    style={{
                                        fontSize: 11,
                                        fontWeight: 500,
                                        color: "rgba(91, 91, 91, 1)",
                                        margin: 0
                                    }}
                                >
                                    PO #
                                </p>
                                <p
                                    style={{
                                        fontSize: 11,
                                        fontWeight: 700,
                                        color: "rgba(80, 80, 80, 1)",
                                        margin: 0
                                    }}
                                >
                                   {data?.ponumber}
                                </p>
                            </td>
                            <td
                                style={{
                                    width: "23.33%",
                                    textAlign: "right",
                                    verticalAlign: "bottom",
                                    fontSize: 11,
                                    fontWeight: 700,
                                    color: "rgba(80, 80, 80, 1)",
                                    margin: 0
                                }}
                            >
                                {moment(data?.created).format('MMM-DD-YYYY')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    style={{
                        color: "rgb(0, 0, 0)",
                        fontSize: 11,
                        fontFamily: "Arial, Helvetica, sans-serif",
                        width: "100%"
                    }}
                >
                    <table
                        style={{
                            width: "100%",
                            fontSize: 11,
                            fontFamily: "Arial, Helvetica, sans-serif"
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 11,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "60%",
                                        borderBottom: "1px solid rgb(239, 244, 247)"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 11,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "24%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Dealer
                                                </td>
                                                <td
                                                    style={{
                                                        width: "76%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                   {data?.name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "24%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Size
                                                </td>
                                                <td
                                                    style={{
                                                        width: "76%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700
                                                    }}
                                                >
                                                   {data?.productspecification?.dimensionA + "’" + " " + data?.productspecification?.dimensionB + "”"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 11,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "40%",
                                        borderBottom: "1px solid rgb(239, 244, 247)"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 11,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "40%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Radius
                                                </td>
                                                <td
                                                    style={{
                                                        width: "60%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    {data?.productspecification?.radius}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "40%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Color
                                                </td>
                                                <td
                                                    style={{
                                                        width: "60%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700
                                                    }}
                                                >
                                                   {data?.productspecification?.covercolor}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{
                            width: "100%",
                            fontSize: 11,
                            fontFamily: "Arial, Helvetica, sans-serif"
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 11,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "67%",
                                        borderBottom: "1px solid rgb(239, 244, 247)"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 11,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "10%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 5
                                                    }}
                                                >
                                                    {data?.productspecification?.tiedown}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "90%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    <p style={{ margin: 0 }}>Plastic</p>
                                                    <p style={{ margin: 0 }}># Of Tie Downs</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "10%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    0
                                                </td>
                                                <td
                                                    style={{
                                                        width: "90%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Connector
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "10%",
                                                        verticalAlign: "top",
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    {/* <img
                                                        style={{ width: "11.5px" }}
                                                        src="https://d1yfmfkyn2ebhw.cloudfront.net/images/app/images/check-circle.png"
                                                    /> */}
                                                    --
                                                </td>
                                                <td
                                                    style={{
                                                        width: "90%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Velcro
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "10%",
                                                        verticalAlign: "top",
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: "11.5px" }}
                                                        src="https://d1yfmfkyn2ebhw.cloudfront.net/images/app/images/check-circle.png"
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        width: "90%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Custom
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 11,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "33%",
                                        borderBottom: "1px solid rgb(239, 244, 247)"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 11,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "18%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    {parseInt(itemsView?.productspecification?.coverskritlength) * 2 + 1}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "82%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Cut Flap
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "18%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    {itemsView?.productspecification?.coverskritlength}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "82%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Flaps
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "18%",
                                                        verticalAlign: "top",
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: "11.5px" }}
                                                        src="https://d1yfmfkyn2ebhw.cloudfront.net/images/app/images/check-circle.png"
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        width: "82%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 5
                                                    }}
                                                >
                                                    Connected
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "18%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        paddingBottom: 7
                                                    }}
                                                >
{data?.productspecification?.tiedown}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "82%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500,
                                                        paddingBottom: 7
                                                    }}
                                                >
                                                    Tie Downs
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "18%",
                                                        verticalAlign: "top",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700
                                                    }}
                                                >
                                                    {parseInt(data?.productspecification?.tiedown) * 2 + 1}
                                                </td>
                                                <td
                                                    style={{
                                                        width: "82%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Cut T/D
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{
                            width: "100%",
                            fontSize: "10.5px",
                            fontFamily: "Arial, Helvetica, sans-serif"
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: "10.5px",
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "22%",
                                        borderBottom: "1px solid rgb(239, 244, 247)"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: "10.5px",
                                            fontFamily: "Arial, Helvetica, sans-serif"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "7%", verticalAlign: "top" }}>
                                                    <img
                                                        style={{ width: 11 }}
                                                        src="https://d1yfmfkyn2ebhw.cloudfront.net/images/app/images/check-circle.png"
                                                    />
                                                </td>
                                                <td
                                                    style={{
                                                        width: "93%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)"
                                                    }}
                                                >
                                                        {data && data?.productspecification && data?.productspecification?.upgrades && data?.productspecification?.upgrades?.map((eachItem, key) => {
                                        return (
                                    <td key={key}><p>{eachItem}</p></td>
                                 
                                        )

                                    })}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{
                            width: "100%",
                            fontSize: 10,
                            fontFamily: "Arial, Helvetica, sans-serif"
                        }}
                    >
                        <tbody>
                            <tr>
                                <td style={{ verticalAlign: "top", width: "40%", paddingTop: 7 }}>
                                    <img
                                        style={{
                                            width: 115,
                                            height: 115,
                                            padding: 8,
                                            border: "1px solid rgba(215, 215, 215, 1)"
                                        }}
                                         src="https://spacovers.imgix.net/spacoversdev/admin/theme/images/qr-code.png" className="qrImg" id="qrImg"
                                    />
                                </td>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 10,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "60%",
                                        paddingLeft: 5,
                                        paddingTop: 7
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 10,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "100%",
                                                        verticalAlign: "top",
                                                        paddingBottom: 4
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 60 }}
                                                        src={`${image}${data && data?.productspecification?.productimage}`}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: "9.5px",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "44%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontSize: 10,
                                                        fontWeight: 500,
                                                        margin: 0,
                                                        paddingBottom: 0
                                                    }}
                                                >
                                                    Foam Density
                                                </td>
                                                <td
                                                    style={{
                                                        width: "56%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        fontSize: 10,
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700,
                                                        margin: 0
                                                    }}
                                                >
                                                    {data?.productspecification?.foamdensity}
                                                </td>
                                            </tr>   
                                        </tbody>
                                    </table>
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: "9.5px",
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        width: "30%",
                                                        verticalAlign: "top",
                                                        color: "rgba(91, 91, 91, 1)",
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Cust/Add
                                                </td>
                                                <td
                                                    style={{
                                                        width: "70%",
                                                        verticalAlign: "top",
                                                        textAlign: "left",
                                                        color: "rgba(80, 80, 80, 1)",
                                                        fontWeight: 700
                                                    }}
                                                >
                                                   {data && data?.deliveryaddress && (data?.deliveryaddress?.address1 + "," + data.deliveryaddress.address2 + "," + data.deliveryaddress.state + "," + data.deliveryaddress.pincode)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table
                        style={{
                            width: "100%",
                            fontSize: 10,
                            fontFamily: "Arial, Helvetica, sans-serif",
                            paddingTop: 5
                        }}
                    >
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        verticalAlign: "top",
                                        fontSize: 10,
                                        fontFamily: "Arial, Helvetica, sans-serif",
                                        width: "100%"
                                    }}
                                >
                                    <table
                                        style={{
                                            width: "100%",
                                            fontSize: 10,
                                            fontFamily: "Arial, Helvetica, sans-serif",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "100%", verticalAlign: "top" }}>
                                                    <p
                                                        style={{
                                                            color: "rgba(91, 91, 91, 1)",
                                                            fontSize: 10,
                                                            fontWeight: 700,
                                                            margin: 0,
                                                            paddingBottom: 2
                                                        }}
                                                    >
                                                        Note
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: 10,
                                                            color: "rgba(80, 80, 80, 1)",
                                                            fontWeight: 500,
                                                            margin: 0,
                                                            wordBreak: "break-word"
                                                        }}
                                                    >
                                                       {data?.productspecification?.additionalinstructions}
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        );
    };

    console.log("selectionOrder--->", selctionOrder, componentRef)
    console.log("items--->", items)
    // $("#qrImg").attr("src", 'https://api.qrserver.com/v1/create-qr-code/?data=' + "https://development.d2bexra7zdcqo3.amplifyapp.com/item/" + itemsView.itemid + "/" + itemsView.status);
    return (
        <>
            <div id="layout-wrapper">
                <Header />
                <Sidebar />

                {isLoading ? <Loader /> :

                    <div className="main-content user-management content-management export create_orders view_orders">

                        <div className="page-content">
                            <div className="container-fluid">



                                <div className="row mb-4 breadcrumb">
                                    <div className="col-lg-12">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <h4 className="mb-2 card-title"> Order #</h4>

                                            </div>
                                            <div className="d-flex align-items-center print_block">
                                                {orderInfo.status != "NEW" && orderInfo.status != "CANCELLED" &&orderInfo.status!==
"ARCHIVE"&&

                                                    <>
                                                        {/* <a className="btn btn-primary me-2" onClick={handlePrint}><span className="material-icons">print</span>Bulk print</a><div style={{ display: 'none' }}>
                                                        <PrintContent ref={componentRef} />
                                                    </div> */}
                                                    
                                                        <a className="btn btn-primary me-2" onClick={handlePrint1}><span className="material-icons">print</span>Bulk print</a>
                                                        <a className="btn btn-primary me-2" onClick={handleCancel}>Cancel order</a>
                                                        <div className='print-only' ref={componentRef1}>
                                                            {items && items.map((data) => (
                                                                <PageContent data={data} />
                                                            ))}
                                                        </div>
                                                    </>

                                                }
                                                  {orderInfo.status === "CANCELLED" &&

<>
    {/* <a className="btn btn-primary me-2" onClick={handlePrint}><span className="material-icons">print</span>Bulk print</a><div style={{ display: 'none' }}>
    <PrintContent ref={componentRef} />
</div> */}

    <a className="btn btn-primary me-2" onClick={handleDelete}>Delete order</a>
   
</>

}
                                                <a onClick={handleBack} className="btn btn-primary ">back</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row table-data " >
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">

                                                <div className="shippingsection order_address">
                                                    <div className="d-flex align col-md-3">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar-xs">
                                                                <div className="avatar-title rounded-circle bg-light text-primary">
                                                                    <span className="material-icons-outlined">person</span>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h5 className="font-size-14 mb-1 mt-1">Wholesaler Name</h5>
                                                            <p className="text-muted">{orderInfo?.name}</p>
                                                        </div>
                                                    </div>


                                                    <div className="d-flex col-md-4">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar-xs">
                                                                <div className="avatar-title rounded-circle bg-light text-primary">
                                                                    <span className="material-icons-outlined">
                                                                        location_on
                                                                    </span>
                                                                </div>

                                                            </div>


                                                        </div>



                                                        <div className="flex-grow-1 ">
                                                            <h5 className="font-size-14 mb-1 mt-1">Billing Address</h5>
                                                            <p className="text-muted">{
                                                                orderInfo && orderInfo?.billingaddress && (orderInfo?.billingaddress?.address1 + "," + " " + orderInfo.billingaddress.address2 + "," + " " + orderInfo.billingaddress.state + "," + " " + orderInfo.billingaddress.pincode)

                                                            }
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex col-md-4">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar-xs">
                                                                <div className="avatar-title rounded-circle bg-light text-primary">
                                                                    <span className="material-icons-outlined">
                                                                        local_shipping
                                                                    </span>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h5 className="font-size-14 mb-1 mt-1">Shipping Address</h5>
                                                            <p className="text-muted">{orderInfo && orderInfo?.deliveryaddress && (orderInfo?.deliveryaddress?.address1 + "," + " " + orderInfo.deliveryaddress.address2 + "," + " " + orderInfo.deliveryaddress.state + "," + " " + orderInfo.deliveryaddress.pincode)}
                                                            </p>
                                                        </div>
                                                    </div>


                                                    {/* <div className="d-flex align">

                                                    <div className="flex-grow-1">
                                                        {orderInfo.status != "NEW" &&
                                                            <div className="d-flex justify-content-end align-items-center print">
                                                                <a className="btn" onClick={handlePrint}><span className="material-icons">print</span>print</a>
                                                                <div style={{ display: 'none' }}>
                                                                    <PrintContent ref={componentRef} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div> */}

                                                </div>
                                                {items.length <= 0 ? "" :




                                                    <div className="workshopdetails sewers_details">
                                                        <h5>WORKSHOP DETAILS</h5>
                                                        <div className="shippingsection">
                                                            <div className="row">
                                                                <div className="d-flex col-md-4 align-items-center">
                                                                    <h5 className="font-size-14 mb-1 mt-1">workshop Name</h5>
                                                                    <p className="text-muted">{orderInfo && orderInfo.workshopinfo && orderInfo.workshopinfo[0]?.workshopname}</p>
                                                                </div>
                                                                {orderInfo&&orderInfo?.reason?.length>0&&  <div className="d-flex col-md-4 align-items-center">
                                                                    <h5 className="font-size-14 mb-1 mt-1">cancellation Reason</h5>
                                                                    <p className="text-muted">{orderInfo && orderInfo.reason}</p>
                                                                </div>}
                                                               
                                                                {/* <div className="d-flex col-md-4 align-items-center">
                                                                    <h5 className="font-size-14 mb-1 mt-1">Cutting User Name</h5>
                                                                    <p className="text-muted">{orderInfo && orderInfo.cuttinginfo && orderInfo.cuttinginfo[0]?.cuttingusername}</p>
                                                                </div>
                                                            </div>
                                                            <h5 className="mb-1">Sewers</h5>
                                                            <div class="sewers_block"> */}
                                                                {orderInfo && orderInfo.sewinginfo && orderInfo.sewinginfo.length > 0 && orderInfo.sewinginfo.map((item, i) => {
                                                                    return (

                                                                        <>

                                                                            <div key={i} class="sewers_name d-flex align-items-center justify-content-between">
                                                                                <p className="text-muted">{item.sewingusername}</p>
                                                                                <p className="text-muted">{item.sewingQuantity}</p>
                                                                            </div>




                                                                        </>

                                                                    );
                                                                })}
                                                            </div>


                                                        </div>
                                                    </div>}

                                                {items.length <= 0 ? null : <>
                                                    {/* {selctionOrder && selctionOrder === "spacovers" ? */}
                                                    <>< DataTable
                                                        // title=""
                                                        columns={columns}
                                                        // className="table align-middle table-nowrap table-check"
                                                        keyField='_id'
                                                        data={items}
                                                        direction="auto"
                                                        highlightOnHover
                                                        fixedHeaderScrollHeight="300px"
                                                        pagination
                                                        responsive
                                                        persistTableHead
                                                        // selectableRowsVisibleOnly
                                                        striped
                                                        // selectableRowsHighlight
                                                        // selectableRows

                                                        defaultSortField="name"
                                                        subHeaderWrap
                                                        noDataComponent={customNoRecords()}
                                                        paginationTotalRows={items.length}
                                                        onChangeRowsPerPage={handlePerRowsChange}
                                                        onChangePage={handlePageChange}
                                                        paginationPerPage={rowsPerPage}
                                                        paginationDefaultPage={currentPageNew}
                                                        paginationRowsPerPageOptions={[15, 25, 50, 75, 100]}

                                                        paginationComponentOptions={{
                                                            rowsPerPageText: 'Per page:',
                                                            rangeSeparatorText: 'of',
                                                            noRowsPerPage: false,
                                                            selectAllRowsItem: false,
                                                            selectAllRowsItemText: 'All',
                                                        }}
                                                        // sortFunction={customSort}
                                                        progressPending={isLoading}
                                                        progressComponent={<TableLoader />}
                                                    /> </>
                                                    {/* :
                                                        <div className="row view_orders_row" >
                                                            {items && items.map(function (item, i) {
                                                                return (
                                                                    <div className="col-md-2">

                                                                        <div className="card">
                                                                            <div className="card-body">
                                                                                <div className="product-img position-relative">
                                                                                    <img onClick={e => handleViewItem(e, item.itemid)}

                                                                                        name="productshape"
                                                                                        src={`${image}${item && item?.productimage}`}
                                                                                        alt=""
                                                                                        className="img-fluid mx-auto d-block"
                                                                                    />
                                                                                </div>
                                                                                <div className="mt-4 text-center product-descptn">
                                                                                    <h5 className="mb-3 text-truncate product-title"><a href="javascript: void(0);" className="text-dark">{item.productname} </a></h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>)
                                                            })}
                                                          

                                                        </div>
                                                    } */}



                                                </>}



                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>


                        <Modal className="access-denied order_specification" show={popup}>

                            <div className="modal-body enquiry-form">
                                <button className="close-btn" onClick={e => setPopup(false)}><span className="material-icons">close</span></button>
                                <h5 className="mt-0">ORDER ID : <span>{itemsView.ordernumber}</span></h5>
                                <div className="order_details">
                                    <div className="order_item">
                                        <h5 className="font-size-14 mt-0">Selected Product</h5>
                                        <div className="shape_image">
                                            <img src={image + itemsView?.productspecification?.productimage} alt="" className="img-fluid d-block" />
                                            <p>{itemsView?.productspecification?.productname}</p>
                                        </div>
                                        {/* <h5 className="font-size-14">scan qr code</h5>
                                        <div className="shape_image">
                                            <img  className="qrImg" id="qrImg" />
                                        </div> */}
                                    </div>

                                    <div className="order_description">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                {/* <div className="flex-shrink-0 me-3">
                                                        <div className="avatar-xs">
                                                            <div className="avatar-title rounded-circle bg-light text-primary">
                                                                <span className="material-icons-outlined"> person</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="font-size-14 mb-1 mt-1">Customer Name</h5>
                                                        <p className="text-muted">{itemsView.customername}</p>

                                                    </div> */}
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <div className="avatar-title rounded-circle bg-light text-primary">
                                                            <span className="material-icons-outlined"> local_shipping</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-14 mb-1 mt-1">Order Date</h5>
                                                    <p className="text-muted">{new Date(itemsView?.created).toLocaleDateString('en-US', {
                                                        month: 'short',
                                                        day: 'numeric',
                                                        year: 'numeric',
                                                        hour: 'numeric',
                                                        minute: 'numeric',
                                                    })}</p>


                                                </div>
                                            </div>
                                        </div>
                                        <h5 className="product_title">Product Specifications</h5>
                                        <div className="table-responsive">

                                            <table className="table table-striped mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td >Cover  Color</td>
                                                        <td >{itemsView && itemsView.productspecification && itemsView.productspecification.covercolor}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Cover Fold</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.coverfold}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tie Downs</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.tiedown}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tie Down Locations</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.tiedownlocation}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Cover Skirt Length</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.coverskritlength}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Skirt Options</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.skirtoption}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Foam Density</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.foamdensity}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Upgrades</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.upgrades}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dimension A</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.dimensionA}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Dimension B</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.dimensionB}</td>
                                                    </tr><tr>
                                                        <td>Radius</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.radius}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Additional Instructions</td>
                                                        <td>{itemsView && itemsView.productspecification && itemsView.productspecification.additionalinstructions}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        {!orderInfo?.reason&&    <div className="d-flex justify-content-end align-items-center print">
                                            <a className="btn" onClick={handlePrint}><span className="material-icons">print</span>print</a>
                                            <div style={{ display: 'none' }}>
                                                <PrintContent ref={componentRef} />
                                            </div>
                                            {/* <a href="#" className="btn"><span className="material-icons">print</span>print</a> */}
                                            {/* <img src="https://spacovers.imgix.net/spacoversdev/admin/theme/images/barcode.jpg?auto=compress,format" alt="" className="img-fluid d-block" /> */}
                                        </div>}


                                    </div>



                                </div>


                            </div>

                        </Modal>
                        {isCancelPopupOpen&&
                         <Modal className="advance-search search-popup create-combination-pop deal-pop-up" show={true} onHide={handleCloseDealPopup} backdrop="static">
                         <Modal.Header closeButton>
                             <Modal.Title>Cancel Order</Modal.Title>
                         </Modal.Header>
                         <Modal.Body>
                             <div>

                                 <div className="row">
                                 <p>Do you want to cancel the order? Please provide a reason.</p>

                                     <div className="col-md-12">
                                         <div className="form-group">
                                             <label className="form-label form-label">reason</label>
                                             <textarea id="verticalnav-address-input" name="followup" onChange={(e) => setReason(e.target.value)} value={reason} placeholder="Add Reason For Approval" class="form-control" rows="2" spellcheck="false"></textarea>
                                         </div>
                                     </div>

                                 </div>
                             </div>
                         </Modal.Body>
                         <Modal.Footer>
                             <div className="adv_footer">

                                 <button  className={`fill_btn yellow-gradient float-end ${reason === "" ? "disable pe-none" : ""}`} onClick={handleCancelOrder}
                                    
                                 >{loaderEnable ? (<img src="https://spacovers.imgix.net/spacoversdev/common/images/rotate_right.svg" className="loading-icon"/>) : null}submit</button>

                                 {/* <button
                                    
                                     className={`fill_btn yellow-gradient float-end ${reason === "" ? "disable" : ""}`} disabled={reason === ""}
                                 >
                                   
                                     accept
                                 </button> */}

                                 <div className="clearfix"></div>
                             </div>
                         </Modal.Footer>
                     </Modal>}
                     <Modal className="access-denied" show={isDelete}>

<div className="modal-body enquiry-form">
    <div className="container">
        <button className="close-btn" onClick={e => setIsdelete(false)}><span className="material-icons">close</span></button>
        <span className="material-icons access-denied-icon">delete_outline</span>
        <h3>Delete</h3>
        <p>This action cannot be undone.</p>
        <p>Are you sure you want to delete perminantly ?</p>
        <div className="popup-footer">
            <button className="fill_btn yellow-gradient" data-bs-toggle="modal" data-bs-target="#recommendModal" onClick={e => handleDeleteOrder()}> {loader ? (<img src="https://spacovers.imgix.net/spacoversdev/common/images/rotate_right.svg" className="loading-icon" />) : null}Yes, Delete</button>
        </div>
    </div>
</div>

</Modal>
                     <SweetAlert show={successOrder}
                                        custom
                                        confirmBtnText="ok"
                                        confirmBtnBsStyle="primary"
                                        title={"Order cancelled successfully"}
                                        onConfirm={e => setSuccessOrder(false)}
                                    >
                                    </SweetAlert>
                       
                        <Footer />
                    </div>}



            </div>
        </>
    );
};

export default ViewOrder;